import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout'
import '../components/base.css'
import { SignupQuery } from '../../graphql-types'
import { withPreview } from 'gatsby-source-prismic'
import extractGlobals from '../lib/extractGlobals'
import EmailSignupForm from '../components/EmailSignupForm'
import {
  Section,
  SectionContent,
  SectionTitle,
} from '../components/FrontMatter'
import { mq, spaces, styled, typography } from '../styles'

const Signup: FC<PageProps<SignupQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)
  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      <Section>
        <Title>Get On The List</Title>
        <Subtitle>
          Sign up below to be the first to gain access to Batch Eight speakers
          and our digital membership.
        </Subtitle>
        <SectionContent>
          <EmailSignupForm theme={'light'} group={'waitlist'} />
        </SectionContent>
      </Section>
    </Layout>
  )
}

export default withPreview(Signup)

const Title = styled(SectionTitle)(
  mq({
    paddingBottom: [0, 0, 0],
  }),
)

const Subtitle = styled.div(
  typography.tinyMono,
  mq({
    textAlign: 'center',
    marginBottom: spaces.medium,
  }),
)

export const pageQuery = graphql`
  query Signup {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
  }
`
